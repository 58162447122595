import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestInfoComponent } from './request-info.component';
import { ShareModule } from 'src/app/common/share/share.module';



@NgModule({
  declarations: [RequestInfoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, ShareModule],
  exports: [RequestInfoComponent]
})
export class RequestInfoModule { }
