import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequirementInfoComponent } from './requirement-info.component';
import { RouterModule, Routes } from '@angular/router';
import { ShareModule } from 'src/app/common/share/share.module';

@NgModule({
  declarations: [RequirementInfoComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, ShareModule],
})
export class RequirementInfoModule { }
