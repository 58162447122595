<div class="margin-adjust h100">
    <div class="v-flex h100">
        <div mat-dialog-title class="text-center text-danger pt-3">
            <div class="d-flex align-items-center justify-content-center header">
                <ion-icon src="assets/svg/triangle.svg"></ion-icon>
                <div class="ml-2">Alert</div>
            </div>
        </div>
        <div mat-dialog-content class="container-b text-center flex-1">
            <div class="ab">
                <div class="rel">
                    <ul class="error-message">
                        <li class="d-flex align-items-center justify-content-center" *ngFor="let errMsg of message.errorMessage">
                            <!-- <ion-icon src="assets/svg/triangle.svg" class="mr-2 tri-icon"></ion-icon> -->
                            <div class="text-center">{{errMsg.msgDescription}}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer">
            <div mat-dialog-actions class="d-flex align-items-center justify-content-center">
                <button mat-raised-button color="danger" (click)="onNoClick()">Close</button>
            </div>
        </div>
    </div>
</div>